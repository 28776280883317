<template>
	<div>
		<!-- If modal have a "pui-select" or "pui-date-field", set property :overflow="false" -->
		<!-- If fixed width is needed, set property :widthDialog="XXX" -->
		<pui-modal-dialog-form
			:titleText="$t('modal.stop.alertSubscription.title')"
			:modelName="modelName"
			:dialogName="alertSubscription"
			:onOk="onOkAlertSubscription"
			:onShow="onShowAlertSubscription"
			:showDialogProp="showDialogAlertSubscription"
			:widthDialog="500"
		>
			<template slot="message">
				<v-container v-if="model">
					<v-layout wrap align-center>
						<v-flex xs10>
							<span class="notif__title">{{ $t('operationsvisitdetailnotifications.notifications') }}</span>
						</v-flex>
						<v-flex xs2>
							<v-switch v-model="notifications"></v-switch>
						</v-flex>
					</v-layout>

					<v-layout v-if="model" wrap class="pt-3">
						<v-flex xs12>
							<span class="notif__subtitle">{{ $t('operationsvisitdetailnotifications.events') }}</span>
						</v-flex>
						<v-flex xs6 v-for="(event, index) in events" :key="index">
							<v-tooltip top>
								<template v-slot:activator="{ on }">
									<v-checkbox
										v-model="model[event.property]"
										:readonly="!notifications"
										color="primary"
										:ripple="false"
										hide-details
									>
										<span slot="label" v-on="on">{{ event.name }}</span>
									</v-checkbox>
								</template>
								<span>{{ event.description }}</span>
							</v-tooltip>
						</v-flex>
					</v-layout>

					<v-layout v-if="model" wrap class="pt-3">
						<v-col cols="12">
							<v-row>
								<span class="notif__subtitle">{{ $t('operationsvisitdetailnotifications.status.title') }}</span>
							</v-row>
							<v-row>
								<v-checkbox
									:label="$t('operationsvisitdetailnotifications.status.cancelled')"
									:readonly="!notifications"
									v-model="model.cancelled"
									color="primary"
									:ripple="false"
									hide-details
								></v-checkbox>
							</v-row>
							<v-row>
								<v-checkbox
									:label="$t('operationsdatatable.status.delayed.nowGreaterThanEta')"
									:readonly="!notifications"
									v-model="model.delayeta"
									color="primary"
									:ripple="false"
									hide-details
								></v-checkbox>
							</v-row>
							<v-row v-if="false">
								<v-checkbox
									:label="$t('operationsdatatable.status.delayed.etcGreaterThanEtd')"
									:readonly="!notifications"
									v-model="model.delayetc"
									color="primary"
									:ripple="false"
									hide-details
								>
								</v-checkbox>
							</v-row>
							<v-row v-if="false">
								<v-checkbox
									:label="$t('operationsvisitdetailnotifications.visitEtaManual')"
									:readonly="!notifications"
									v-model="model.visitetamanual"
									color="primary"
									:ripple="false"
									hide-details
								></v-checkbox>
							</v-row>
						</v-col>
					</v-layout>
				</v-container>
			</template>
		</pui-modal-dialog-form>
		<pui-modal-dialog-form
			:titleText="$t('modal.visit.transitions.title')"
			:modelName="modelName"
			:dialogName="visitTransitions"
			:onShow="onShowVisitTransitions"
			:onOk="onOkVisitTransitions"
			:showDialogProp="showDialogVisitTransitions"
		>
			<template slot="message">
				<v-row dense>
					<v-col cols="12">
						<pui-select
							:id="`transition-${modelName}`"
							:attach="`transition-${modelName}`"
							:label="$t('modal.visit.transitions.transition')"
							toplabel
							clearable
							v-model="selectTransitionsMainModel"
							modelName="visitstatustransition"
							:itemsToSelect="itemsToSelectMain()"
							:modelFormMapping="{ statusto: 'statusto' }"
							itemValue="statusto"
							:itemText="(item) => `${item.description} - ${item.statusfromname} - ${item.statustoname}`"
							:order="{ description: 'asc' }"
							reactive
							:fixedFilter="filterVisitByPortAuthorityAndStatusFrom"
						></pui-select>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="12" v-if="hasStops">
						<h3>{{ $t('modal.visit.transitions.stoptransitions') }}</h3>
						<ul>
							<li v-for="(stop, index) in stops" :key="stop.id">
								<v-container>
									<v-row>
										<v-col cols="4">
											<span>{{ stop.num }} - {{ stop.status }} </span>
										</v-col>
										<v-col cols="8">
											<pui-select
												:id="`transition-${modelName}`"
												:attach="`transition-${modelName}`"
												:label="$t('modal.visit.transitions.transition')"
												toplabel
												clearable
												v-model="selectTransitionsStopsModel[index]"
												modelName="stopstatustransition"
												:itemsToSelect="itemsToSelectStops(index)"
												:modelFormMapping="{ statusto: 'statusto' }"
												itemValue="statusto"
												:itemText="(item) => `${item.description} - ${item.statusfromname} - ${item.statustoname}`"
												:order="{ description: 'asc' }"
												reactive
												:fixedFilter="filterStopsByPortAuthorityAndStatusFrom[index]"
											></pui-select>
										</v-col>
									</v-row>
								</v-container>
							</li>
						</ul>
					</v-col>
				</v-row>
			</template>
		</pui-modal-dialog-form>
		<pui-modal-dialog-form
			:titleText="$t('modal.visit.compareWithShipMaster.title')"
			:modelName="modelName"
			:dialogName="compareWithShipMaster"
			:onShow="onShowCompareWithShipMaster"
			:onOk="onOkCompareWithShipMaster"
			:showDialogProp="showDialogCompareWithShipMaster"
			disableCancel
		>
			<template slot="message" v-if="showDialogCompareWithShipMaster">
				<v-row dense v-if="hasDifferences">
					<v-col cols="12">
						<h3>{{ $t('modal.visit.compareWithShipMaster.differences') }}</h3>
						<ul>
							<li v-for="difference in shipDifferences" :key="difference.name">
								<v-container>
									<v-row>
										<v-col cols="12">
											<span>
												{{ $t('modal.visit.compareWithShipMaster.visit') }} -
												{{ $t(`form.ship.${difference.name.toLowerCase()}`) }} : {{ difference.visitValue }} /
												{{ $t('modal.visit.compareWithShipMaster.portauthority') }} -
												{{ $t(`form.ship.${difference.name.toLowerCase()}`) }} : {{ difference.APvalue }}</span
											>
										</v-col>
									</v-row>
								</v-container>
							</li>
						</ul>
					</v-col>
				</v-row>
				<v-row dense v-else>
					<v-col cols="12">
						<h3>{{ $t('modal.visit.compareWithShipMaster.nodifferences') }}</h3>
					</v-col>
				</v-row>
			</template>
		</pui-modal-dialog-form>

		<pui-modal-dialog-form
			:titleText="$t('actions.visit.finishVisit')"
			:modelName="modelName"
			dialogName="finishVisit"
			:onOk="onOkFinishVisit"
			:showDialogProp="showDialogFinishVisit"
			:onShow="onShowFinishVisit"
			:overflow="false"
		>
			<template slot="message" slot-scope="data">
				<pui-date-field
					:id="`atd-${modelName}`"
					:label="$t('visit.atd')"
					v-model="data.modalData.atd"
					toplabel
					required
					time
				></pui-date-field>
			</template>
		</pui-modal-dialog-form>

		<pui-modal-dialog-form
			:titleText="$t('actions.visit.reinitiateVisit')"
			:modelName="modelName"
			dialogName="reinitiateVisit"
			:onOk="onOkReinitiateVisit"
			:overflow="false"
		>
			<template slot="message">
				<v-col cols="12">
					<h3>{{ $t('modal.visit.reinitiateVisit.sure') }}</h3>
				</v-col>
			</template>
		</pui-modal-dialog-form>

		<pui-modal-dialog-form
			:titleText="$t('actions.visit.linkBookingStop')"
			:modelName="modelName"
			:dialogName="linkBookingStop"
			:onShow="onShowLinkBookingStop"
			:onOk="onOkLinkBookingStop"
			:showDialogProp="showDialogLinkBookingStop"
			:overflow="false"
		>
			<template slot="message">
				<pui-field-set :title="$t('actions.visit.fieldset.visitStop')">
					<v-row dense>
						<v-col cols="12">
							<pui-select
								:id="`stop-${modelName}`"
								:attach="`stop-${modelName}`"
								:label="$t('actions.visit.stop')"
								toplabel
								clearable
								required
								v-model="model"
								modelName="stop"
								:itemsToSelect="[{ id: this.model.stop }]"
								:modelFormMapping="{ id: 'stop' }"
								itemValue="id"
								:itemText="(item) => getStopItemText(item)"
								:order="{ eta: 'asc' }"
								:fixedFilter="filterByVisit"
								reactive
								@input="getStopBooking()"
							></pui-select>
						</v-col>
					</v-row>
				</pui-field-set>
				<pui-field-set :title="$t('actions.visit.fieldset.booking')">
					<v-row dense>
						<v-col cols="12">
							<pui-select
								:id="`booking-${modelName}`"
								:attach="`booking-${modelName}`"
								:label="$t('actions.visit.booking')"
								toplabel
								clearable
								required
								v-model="model"
								modelName="booking"
								:itemsToSelect="[{ id: this.model.bookingid }]"
								:modelFormMapping="{ id: 'bookingid' }"
								itemValue="id"
								:itemText="(item) => getBookingItemText(item)"
								:order="{ eta: 'asc' }"
								:fixedFilter="bookingFixedFilter"
								reactive
							></pui-select>
						</v-col>
					</v-row>
				</pui-field-set>
			</template>
		</pui-modal-dialog-form>
	</div>
</template>

<script>
import EventsMixin from '@/mixins/EventsMixin';

export default {
	name: 'visitmodals',
	mixins: [EventsMixin],
	data() {
		return {
			alertSubscription: 'alertSubscription',
			showDialogAlertSubscription: true,
			notifications: false,
			modelConfig: null,
			model: {},
			selectTransitionsMainModel: {},
			selectTransitionsStopsModel: {},
			modelTemplate: null,
			checkAll: true,
			events: [],
			visitTransitions: 'visitTransitions',
			filterVisitByPortAuthorityAndStatusFrom: {},
			hasStops: false,
			stops: [],
			filterStopsByPortAuthorityAndStatusFrom: {},
			showDialogVisitTransitions: true,
			compareWithShipMaster: 'compareWithShipMaster',
			showDialogCompareWithShipMaster: true,
			ship: {},
			visitShip: {},
			shipDifferences: [],
			hasDifferences: false,
			showDialogFinishVisit: false,
			linkBookingStop: 'linkBookingStop',
			showDialogLinkBookingStop: true,
			bookingFixedFilter: null
		};
	},
	props: {
		modelName: {
			required: true,
			type: String
		}
	},
	watch: {
		notifications(newVal) {
			if (!newVal) {
				for (const property in this.model) {
					if (property === 'usr' || property === 'stop' || property === 'visit') {
						continue;
					}
					this.model[property] = false;
				}
			} else {
				if (!this.checkAll) {
					this.checkAll = true;
					return;
				}
				for (const property in this.model) {
					if (property === 'usr' || property === 'stop' || property.includes('send') || property === 'visit') {
						continue;
					}
					this.model[property] = true;
				}
			}
		}
	},
	methods: {
		itemsToSelectMain() {
			return [{ statusto: this.selectTransitionsMainModel.status }];
		},
		itemsToSelectStops(index) {
			return [{ stopstatusto: this.selectTransitionsStopsModel[index].statusid }];
		},
		onOkAlertSubscription(modalData) {
			return new Promise(async (resolve) => {
				await this.saveSubscription(modalData);
				resolve(true);
			});
		},
		onShowAlertSubscription(modalData) {
			this.fillEvents();

			const params = {
				usr: this.$store.getters.getUser,
				visit: modalData.id
			};
			this.$puiRequests.getRequest(
				'/subscription/get',
				params,
				(response) => {
					this.model = JSON.parse(JSON.stringify(response.data));
					this.modelTemplate = response.data;

					if (this.modelTemplate['usr'] || this.modelTemplate['stop']) {
						this.checkAll = false;
						this.notifications = true;
						for (const property in this.modelTemplate) {
							if (property === 'usr' || property === 'stop') {
								this.modelTemplate[property] = null;
							} else {
								this.modelTemplate[property] = false;
							}
						}
					}
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
				}
			);
		},
		fillEvents() {
			const internalEvents = [];

			for (let j = 0, vesselEvent = this.vesselEvent.length; j < vesselEvent; j++) {
				const event = this.vesselEvent[j];
				const eventName = this.allEventsName[event];
				const eventDescription = this.allEventsDescription[event];
				const eventProperty = `stop${event}`;
				const index = internalEvents.length - 1;
				if (event == 'ata' || event == 'atd') {
					internalEvents.splice(index, 0, { name: eventName, description: eventDescription, property: eventProperty });
				}
			}

			this.events = internalEvents;
		},
		async saveSubscription(modalData) {
			if (!this.model.stop && !this.model.usr) {
				if (this.notifications) {
					await this.insertSubscription(modalData);
				}
			} else {
				if (this.notifications) {
					await this.updateSubscription(modalData);
				} else {
					await this.deleteSubscription(modalData);
				}
			}
			this.menu = false;
		},
		insertSubscription(modalData) {
			this.model.usr = this.$store.getters.getUser;
			this.model.visit = modalData.id;
			this.$puiRequests.postRequest(
				'/subscription/insert',
				this.model,
				() => {
					this.$puiNotify.success(this.$t('pui9.save.success'));
					this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);
					this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);

					this.showDialogAlertSubscription = false;
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
					this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);

					this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);

					this.showDialogAlertSubscription = false;
				}
			);
		},
		updateSubscription(modalData) {
			this.$puiRequests.putRequest(
				'/subscription/update',
				this.model,
				() => {
					this.$puiNotify.success(this.$t('pui9.save.success'));
					this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);
					this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);

					this.showDialogAlertSubscription = false;
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
					this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);
					this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);

					this.showDialogAlertSubscription = false;
				}
			);
		},
		deleteSubscription(modalData) {
			const params = {
				usr: this.$store.getters.getUser,
				visit: modalData.id
			};
			this.$puiRequests.deleteRequest(
				'/subscription/delete',
				null,
				() => {
					this.model = JSON.parse(JSON.stringify(this.modelTemplate));
					this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);
					this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);
					this.$puiNotify.success(this.$t('pui9.delete.success'));

					this.showDialogAlertSubscription = false;
				},

				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
					this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);
					this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);

					this.showDialogAlertSubscription = false;
				},
				params
			);
		},
		onShowVisitTransitions(modalData) {
			if (modalData) {
				this.model = modalData;
				this.stops = this.model.stops;

				for (const [key, value] of Object.entries(this.stops)) {
					this.selectTransitionsStopsModel[key] = value;
				}
				if (this.model.portauthorityid) {
					this.filterVisitByPortAuthorityAndStatusFrom = {
						groups: [],
						groupOp: 'and',
						rules: [
							{ field: 'portauthorityid', op: 'eq', data: this.model.portauthorityid },
							{ field: 'statusfrom', op: 'eq', data: this.model.status }
						]
					};
				} else {
					this.filterVisitByPortAuthorityAndStatusFrom = {
						groups: [],
						groupOp: 'and',
						rules: [
							{ field: 'portauthorityid', op: 'eq', data: window.localStorage.getItem('workingPortAuthorityId') },
							{ field: 'statusfrom', op: 'eq', data: this.model.statusid }
						]
					};
				}

				if (this.stops) {
					this.getStopFiltersByPortAuthorityAndStatusFrom();
					this.hasStops = true;
				}
			}
		},
		getStopFiltersByPortAuthorityAndStatusFrom() {
			if (this.stops) {
				this.hasStops = true;
				for (const [key, value] of Object.entries(this.stops)) {
					this.filterStopsByPortAuthorityAndStatusFrom[key] = {
						groups: [],
						groupOp: 'and',
						rules: [
							{ field: 'portauthorityid', op: 'eq', data: value.portauthorityid },
							{ field: 'statusfrom', op: 'eq', data: value.statusid }
						]
					};
				}
			}
		},
		getStopStatusInfo() {
			let editedStops = [];
			if (this.stops) {
				editedStops = this.stops.map((stop) => ({
					id: stop.id,
					status: stop.statusto
				}));
			}
			return editedStops;
		},
		onOkVisitTransitions() {
			const visitStops = this.getStopStatusInfo();
			const infoToEdit = {
				visitid: this.model.id,
				visitstatus: this.selectTransitionsMainModel.statusto,
				stops: visitStops
			};
			return new Promise(async (resolve) => {
				await this.patchVisitAndStopTransitions(infoToEdit);
				resolve(true);
			});
		},
		patchVisitAndStopTransitions(infoToEdit) {
			if (infoToEdit) {
				if (infoToEdit.visitstatus) {
					const url = this.$store.getters.getModelByName('visit').url.patch;
					const params = { status: infoToEdit.visitstatus };
					const pk = { id: infoToEdit.visitid };
					this.doPatch(url, params, pk);
				}
				if (infoToEdit.stops) {
					const stopsToEdit = infoToEdit.stops;
					const stopUrl = this.$store.getters.getModelByName('stop').url.patch;
					for (let i = 0, stopsToEditLength = stopsToEdit.length; i < stopsToEditLength; i++) {
						const stopToEdit = stopsToEdit[i];
						if (stopToEdit.status) {
							const stopParams = { status: stopToEdit.status };
							const stopPk = { id: stopToEdit.id };
							this.doPatch(stopUrl, stopParams, stopPk);
						}
					}
				}
			}
		},
		doPatch(url, params, pk) {
			this.$puiRequests.patchRequest(
				url,
				params,
				(response) => {
					this.$puiNotify.success(this.$t('pui9.save.success'));
					this.$puiEvents.$emit(`refreshForm-action-running-ended-${this.modelName}`);
					this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);
					this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);
					this.showDialogVisitTransitions = false;
				},
				(error) => {
					this.$puiNotify.error(this.$t('pui9.save.error'));
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
					this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);
					this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);

					this.showDialogVisitTransitions = false;
				},
				pk
			);
		},
		onShowCompareWithShipMaster(modalData) {
			if (modalData.ship) {
				this.showDialogCompareWithShipMaster = true;
				this.model = modalData;

				this.ship = this.model.ship;
				this.visitShip = this.model.shiptechnicaldata;

				for (const [key, value] of Object.entries(this.ship)) {
					for (const [key1, value1] of Object.entries(this.visitShip)) {
						if (key === key1.toLowerCase()) {
							if (value !== value1) {
								if (key.includes('date')) {
									let difference = {
										name: key,
										visitValue: `${this.$dateTimeUtils.getLocalFormattedDate(value1, 'DD/MM/YYYY')}`,
										APvalue: `${this.$dateTimeUtils.getLocalFormattedDate(value, 'DD/MM/YYYY')}`
									};
									this.shipDifferences.push(difference);
								} else {
									let difference = { name: key, visitValue: value1, APvalue: value };
									this.shipDifferences.push(difference);
								}
							}
							break;
						}
					}
				}
				this.hasDifferences = this.shipDifferences.length > 0 ? true : false;
			}
		},
		onOkCompareWithShipMaster() {
			this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);

			if (this.shipDifferences.length > 0) {
				this.shipDifferences = [];
				this.hasDifferences = false;
			}

			this.showDialogCompareWithShipMaster = false;
		},
		async onShowFinishVisit(modalData) {
			const params = {
				portCallNumber: modalData.portcallnumber
			};
			await this.$puiRequests.getRequest(
				'/visit/getAtdVisit',
				params,
				(response) => {
					modalData.atd = response.data;
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
				}
			);
		},
		onOkFinishVisit(modalData) {
			const params = {
				portCallNumber: modalData.portcallnumber,
				date: modalData.atd,
				auto: false
			};

			const url = '/visit/finishVisit';
			return new Promise((resolve) => {
				this.$puiRequests.getRequest(
					url,
					params,
					(response) => {
						// Valid response, do code and close modal
						this.$puiNotify.success(this.$t('pui9.save.success'));
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);
						resolve(true);
					},
					(e) => {
						// Error response, do code and not close modal
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiNotify.error(message);
						resolve(false);
					}
				);
			});
		},
		onOkReinitiateVisit(modalData) {
			const params = { status: 'IN', atd: null };
			const pk = { id: modalData.id };

			return new Promise(async (resolve) => {
				this.$puiRequests.patchRequest(
					this.$store.getters.getModelByName('visit').url.patch,
					params,
					(response) => {
						this.$puiNotify.success(this.$t('pui9.save.success'));
						this.$puiEvents.$emit(`refreshForm-action-running-ended-${this.modelName}`);
						this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);
						this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);
						resolve(true);
					},
					(error) => {
						this.$puiNotify.error(this.$t('pui9.save.error'));
						this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
						this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);
						this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);

						resolve(false);
					},
					pk
				);
			});
		},
		onShowLinkBookingStop(modalData) {
			this.model = modalData;
		},
		onOkLinkBookingStop() {
			const params = {
				stop: this.model.stop
			};
			let pk = {
				id: this.model.bookingid
			};

			return new Promise(async (resolve) => {
				await this.doLinkBookingStop(params, pk);
				resolve(true);
			});
		},

		async doLinkBookingStop(params, pk) {
			if (params) {
				const url = '/booking/linkBookingStop';

				this.$puiRequests.patchRequest(
					url,
					params,
					() => {
						this.$puiNotify.success(this.$t('pui9.save.success'));
						this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);
						this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);

						this.showDialogLinkBookingStop = false;
					},
					(error) => {
						this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
						this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);
						this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);

						this.showDialogLinkBookingStop = false;
					},
					pk
				);
			}
		},
		getStopItemText(item) {
			let startdate = item.ata ? item.ata : item.etaauth ? item.etaauth : item.eta;
			let enddate = item.atd ? item.atd : item.etdauth ? item.etdauth : item.etd;

			return `${item.portcallnumber} - ${item.num} - ${item.location} - ${item.vesselname} - ${this.$dateTimeUtils.getLocalFormattedDate(
				startdate,
				'DD/MM/YYYY HH:mm'
			)}  ${enddate ? '-' + this.$dateTimeUtils.getLocalFormattedDate(enddate, 'DD/MM/YYYY HH:mm') : ''} - ${item.consigneename} - ${
				item.status
			} - ${item.fromportname ? item.fromportname : ''} - ${item.toportname ? item.toportname : ''}`;
		},
		getBookingItemText(item) {
			return `${item.berthname} - ${item.shiptypename} - ${
				item.shipname ? '-' + item.shipname : ''
			} - ${this.$dateTimeUtils.getLocalFormattedDate(item.eta, 'DD/MM/YYYY HH:mm')} - ${this.$dateTimeUtils.getLocalFormattedDate(
				item.etd,
				'DD/MM/YYYY HH:mm'
			)} - ${item.companyname} - ${item.fromportname ? item.fromportname : ''} - ${item.toportname ? item.toportname : ''}`;
		},
		getStopBooking() {
			if (this.model.stop) {
				const opts = {
					model: 'booking',
					filter: {
						groupOp: 'and',
						groups: [],
						rules: [
							{
								field: 'stop',
								op: 'eq',
								data: this.model.stop
							}
						]
					}
				};
				this.$puiRequests.postRequest('/puisearch', opts, (response) => {
					if (response.data && response.data.data[0]) {
						this.model.bookingid = response.data.data[0].id;
						this.bookingFixedFilter = {
							groups: [],
							groupOp: 'and',
							rules: [{ field: 'id', op: 'eq', data: this.model.bookingid }]
						};
						this.getBookingList();
					} else {
						this.model.bookingid = null;
						this.getBookingList();
					}
				});
			}
		},
		getBookingList() {
			let rules = [{ field: 'port', op: 'eq', data: this.model.portid }];

			if (this.model.consigneeid) {
				rules.push({ field: 'companyid', op: 'eq', data: this.model.consigneeid });
			}
			/*
			if (this.model.fromportcode) {
				rules.push({ field: 'fromportcode', op: 'eq', data: this.model.fromportcode });
			}
			if (this.model.toportcode) {
				rules.push({ field: 'toportcode', op: 'eq', data: this.model.toportcode });
			} */

			this.bookingFixedFilter = {
				groups: [],
				groupOp: 'and',
				rules: rules
			};
		}
	},
	computed: {
		filterByVisit() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'visitid', op: 'eq', data: this.model.id }]
			};
		}
	}
};
</script>
